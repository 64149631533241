import React from "react";
import logo from "./logo.svg";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import { useTranslation } from "react-i18next";

import CssBaseline from "@mui/material/CssBaseline";
import RTL from "./rtl-loader";
function App() {
  const { i18n, t } = useTranslation();
  return (
    <>
      <CssBaseline />
      <RTL>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </RTL>
    </>
  );
}

export default App;
