import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import download from "downloadjs";
import { copyToClipboard } from "../../utils";
export type Specs = {
  route: string;
  example: string;
  label: string;
  name: string;
  params: Param[];
};

export type Param = {
  label: string;
  value: string;
  type: string;
};

type Props = {};
const items = [
  {
    name: "ramadan",
    label: "رمضان",

    params: [
      { label: "الاسم", value: "name" },
      { label: "الكود التعريفي", value: "reference" },
      { label: "QR Code", value: "qr" },
    ],
  },
  {
    name: "upload",
    label: "كلاسيكي",
    params: [
      { label: "الى", value: "to" },
      { label: "المرسل", value: "from" },
      { label: "المحتوى", value: "body" },
      { label: "محتوى ملوّن", value: "body2" },
    ],
  },
  {
    name: "mother",
    label: "عيد الام",

    params: [
      { label: "الى", value: "to" },
      { label: "المرسل", value: "from" },
      { label: "المحتوى", value: "body" },
    ],
  },
  {
    name: "eid",
    label: "عيد الفطر",

    params: [
      { label: "الى", value: "to" },
      { label: "المرسل", value: "from" },
      { label: "المحتوى", value: "body" },
    ],
  },
  {
    name: "eid-adha",
    label: "عيد الاضحى",

    params: [
      { label: "الى", value: "to" },
      { label: "المرسل", value: "from" },
      { label: "المحتوى", value: "body" },
    ],
  },
  {
    name: "eid-adha-2023",
    label: "عيد الاضحى ٢٠٢٣",

    params: [
      { label: "الى", value: "to" },
      { label: "المرسل", value: "from" },
      { label: "المحتوى", value: "body" },
    ],
  },
];
const Home: React.FC<Props> = () => {
  // const { t } = useTranslation();
  const [specs, setSpecs] = useState<Specs[]>([]);
  useEffect(() => {
    fetch(`${route}/api/gift/params`)
      .then((res) => res.json())
      .then((data) => {
        setSpecs([...items, ...data]);
        setSelectedImage(data[data.length - 1].name);
      });
  }, []);
  const [selectedImage, setSelectedImage] = useState(items[0].name);
  const [paramsState, setParamsState] = useState({}) as any;
  const [imageUri, setImageUri] = useState("");
  const handleSelectedImageChange = (event: SelectChangeEvent) => {
    setSelectedImage(event.target.value as string);
  };

  const handleParamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParamsState({ ...paramsState, [event.target.name]: event.target.value });
  };
  // const route = "http://localhost:3000";
  const route = "";
  const handleSubmitExample: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    setImageUri(`${route}/api/gift/${selectedImage}/?example=1`);
  };
  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    const params = new URLSearchParams(paramsState);
    console.log(paramsState);
    console.log(params.toString());
    setImageUri(`${route}/api/gift/${selectedImage}/?${params.toString()}`);
  };
  return (
    <Box sx={{ backgroundColor: "#E7EBF0", minHeight: "100vh" }}>
      <Container sx={{ pt: 5 }}>
        <Typography variant="h4" gutterBottom>
          فورم بناء الصور
        </Typography>
        <Paper sx={{ padding: 2, mb: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="template-select-label">القالب</InputLabel>
            <Select
              labelId="template-select-label"
              id="template-select"
              value={selectedImage}
              label="القالب"
              onChange={handleSelectedImageChange}
              sx={{ mb: 2 }}
            >
              {specs.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.name}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            <Stack spacing={2}>
              {specs
                .find((item) => item.name === selectedImage)
                ?.params.map((param, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        multiline={param.value.includes("body")}
                        key={index}
                        id={param.value}
                        label={param.label}
                        variant="outlined"
                        name={param.value}
                        onChange={handleParamsChange}
                        style={{
                          width: "100%",
                        }}
                      />
                      {param.value == "body2" && (
                        <input
                          type="color"
                          onChange={(e) => {
                            setParamsState({
                              ...paramsState,
                              body2Color: e.target.value,
                            });
                          }}
                          value={paramsState.body2Color ?? "#e09e46"}
                        />
                      )}
                    </div>
                  );
                })}

              <Button variant="contained" onClick={handleSubmit}>
                بناء
              </Button>
              <Button variant="contained" onClick={handleSubmitExample}>
                عرض مثال
              </Button>
            </Stack>
          </FormControl>
        </Paper>
        {imageUri !== "" ? (
          <Paper sx={{ p: 1 }}>
            <Stack direction="row" spacing={2} mb={2}>
              <Button
                variant="contained"
                onClick={() => {
                  copyToClipboard(`${window.location.hostname}${imageUri}`);
                }}
              >
                نسخ الرابط
              </Button>
              <Button
                variant="contained"
                onClick={async () => {
                  const image = await fetch(imageUri);

                  download(await image.blob(), selectedImage, "image/png");
                }}
              >
                تنزيل
              </Button>
            </Stack>

            <img src={imageUri} width="100%" />
          </Paper>
        ) : null}
      </Container>
    </Box>
  );
};

export default Home;
